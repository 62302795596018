import React, { useState, useEffect } from 'react'
import Scrollspy from 'react-scrollspy'
import './Main.css'
import IndustryCards from '../../Components/IndustryCards/IndustryCards'
import DumbCards from '../../Components/DumbCards/DumbCards'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Iframe from 'react-iframe'
import pic1 from './clientIcons/bpcl_logo.jpg'
import pic2 from './clientIcons/crypto.png'
import pic3 from './clientIcons/halatalents.png'
import pic4 from './clientIcons/discprofiles.png'
import pic5 from './clientIcons/unilever.png'
import Benefits from '../../Components/benefits'
const finance = [
  {
    title: 'Customer Churn Prediction',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/07/Customer_Churn_Prediction-1.jpg',
    desc: 'AI Helps Retain Valuable Banking Customers',
  },
  {
    title: 'Credit Risk Scoring',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/07/Credit_Risk_Scoring-1.jpg',
    desc: 'Personalizing Credit Decisions with AI',
  },
  {
    title: 'Anti-Money Laundering',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/07/Anti_Money_Laundring-1.jpg',
    desc: 'Stopping Crime with AI.',
  },
  {
    title: 'Know Your Customer/Client (KYC)',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/07/Know_Your_Customer-1.jpg',
    desc: 'Understanding the dynamics of your customer interaction with AI',
  },
  {
    title: 'Fraud Detection',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Fraud_Detection-1.jpg',
    desc: 'Stopping Fraud in its Tracks with AI',
  },
  {
    title: 'Anomaly Detection',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Anomaly_Detection-1.jpg',
    desc: 'Finding Network Anomalies Faster with AI',
  },
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
  {
    title: 'Smart Segmentation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
    desc: 'Understanding Customer Microsegments with AI',
  },
  {
    title: 'Content Personalization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Content_Personalization-1.jpg',
    desc: 'AI Drives Relevant Dialog with Customers',
  },
]
const insurance = [
  {
    title: 'Fraud Mitigation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Fraud_Mitigation-1.jpg',
    desc: 'Finding and Stopping Fraud with AI',
  },
  {
    title: 'Customer Retention',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Customer_Retention-1.jpg',
    desc: 'AI Helps Retain Valuable Insurance Customers',
  },
  {
    title: 'Personalized Rate Management',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/07/use_cases_1.jpg',
    desc: 'Customizing Rates based on Individual Data using AI',
  },
  {
    title: 'Personalized Product Bundling',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Personalized_Product_Bundling-1.jpg',
    desc: 'Bundling the Right Product with AI',
  },
  {
    title: 'Claims Management',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/use_cases_5.jpg',
    desc: 'Streamlining the Claims Process with AI',
  },
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
  {
    title: 'Smart Segmentation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
    desc: 'Understanding Customer Microsegments with AI',
  },
  {
    title: 'Content Personalization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Content_Personalization-1.jpg',
    desc: 'AI Drives Relevant Dialog with Customers',
  },
]
const marketing = [
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
  {
    title: 'Smart Segmentation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
    desc: 'Understanding Customer Microsegments with AI',
  },
  {
    title: 'Content Personalization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Content_Personalization-1.jpg',
    desc: 'AI Drives Relevant Dialog with Customers',
  },
  {
    title: 'Next Best Action',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Action-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
]
const telecom = [
  {
    title: 'Predictive Customer Support',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Customer_Support-1.jpg',
    desc: 'Solving Customer Issues on the First Call',
  },
  {
    title: 'Predictive Fleet Maintenance',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Fleet_Maintenance-1.jpg',
    desc: 'Preventing Vehicle Downtime with AI',
  },
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
  {
    title: 'Smart Segmentation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
    desc: 'Understanding Customer Microsegments with AI',
  },
  {
    title: 'Content Personalization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Content_Personalization-1.jpg',
    desc: 'AI Drives Relevant Dialog with Customers',
  },
  {
    title: 'Next Best Action',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Action-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
]
const manufacturing = [
  {
    title: 'Predictive Manufacturing Design',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Manufacturing_Design-1.jpg',
    desc: 'Finding Optimal Manufacturing Solutions with AI',
  },
  {
    title: 'Predictive Maintenance',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Maintence.jpg',
    desc: 'Fixing Machinery Before a Breakdown with AI',
  },
  {
    title: 'Supply Chain Optimization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Supply_Chain_Optimization-2.jpg',
    desc: 'Finding the best mix across the supply chain',
  },
  {
    title: 'Transportation Optimization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2019/07/Transportation-Optimization.png',
    desc: 'Ensuring optimal quality of products during transportation and delivery',
  },
  {
    title: 'Anomaly Detection',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Anomaly_Detection-1.jpg',
    desc: 'Finding Network Anomalies Faster with AI',
  },
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
  {
    title: 'Smart Segmentation',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
    desc: 'Understanding Customer Microsegments with AI',
  },
]
const retail = [
  {
    title: 'Product Recommendations',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Product_Recommendations-1.jpg',
    desc: 'Increasing Cross-sell and Upsell with AI',
  },
  {
    title: 'Assortment Optimization',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Assortment_Optimization-1.jpg',
    desc: 'Stocking the Right Products to Meet Demand with AI',
  },
  {
    title: 'Pricing Optimization',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/07/use_cases_10.jpg',
    desc: 'Maximizing Profits with AI',
  },
  {
    title: 'Fraud Detection',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Fraud_Detection-1.jpg',
    desc: 'Stopping Fraud in its Tracks with AI',
  },
  {
    title: 'Customer Churn Prediction',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/07/Customer_Churn_Prediction-1.jpg',
    desc: 'AI Helps Retain Valuable Banking Customers',
  },
  {
    title: 'Anomaly Detection',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Anomaly_Detection-1.jpg',
    desc: 'Finding Network Anomalies Faster with AI',
  },
  {
    title: 'Lead Scoring',
    image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
    desc: 'Working the Right Leads with AI',
  },
  {
    title: 'Next Best Offer',
    image:
      'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
    desc: 'The Right Offer at the Right Time with AI',
  },
]
const healthcare = [
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-3.jpeg',
    title: 'Predicting ICU Transfers',
    desc: 'Saving lives by catching patients before the crash with AI',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-7.jpeg',
    title: 'Medical Testing',
    desc: 'Predicting Medical Test Results with AI',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-13.jpeg',
    title: 'Improving Clinical Workflow',
    desc: 'AI provides a Helping Hand to Clinicians',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-9.jpeg',
    title: 'Claims Fraud Detection',
    desc: 'Using AI to Stop $100 Billion in Fraudulent Healthcare Claims',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-5.jpeg',
    title: 'Claim Denials Management',
    desc: 'Streamlining the Claims Process with AI',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-2.jpeg',
    title: 'Predicting Hospital Acquired Infections (HAIs)',
    desc: 'Saving Lives with AI',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-10.jpeg',
    title: 'Predicting Hospital Readmissions',
    desc: 'Preventing Readmissions and Saving Lives with AI',
  },
  {
    image:
      'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-11.jpeg',
    title: 'Sepsis Prevention',
    desc: '80% of Sepsis Deaths Can Be Prevented with Rapid Diagnosis',
  },
]
const financeCard = finance.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const insuranceCard = insurance.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const marketingCard = marketing.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const telecomCard = telecom.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const manufacturingCard = manufacturing.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const retailCard = retail.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})
const healthcareCard = healthcare.map((m, i) => {
  if (i === 1 || i === 4 || i === 7) {
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={true}
      />
    )
  } else
    return (
      <DumbCards
        title={m.title}
        key={i}
        image={m.image}
        desc={m.desc}
        margin={false}
      />
    )
})

const Main = () => {
  const [activeTab, setActiveTab] = useState(1)
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <div id='Home'>
      <div className='mainBanner'>
        <div className='mainBannerText'>
          <div className='display-4 py-4'>
            <b>Industry Solutions</b>
          </div>
          <h6 className='py-2'>
            Across industries, AI is having a dramatic impact by helping to
            improve customer <br />
            experiences, streamlining time-consuming processes, decreasing waste
            and fraud, <br />
            and helping businesses scale and compete in the face of modern
            challenges.
          </h6>
        </div>
      </div>

      <div
        style={{
          boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.2)',
          position: 'sticky',
          top: 0,
          zIndex: 100,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <div className='scrollspy mt-5 pt-5'>
          <Scrollspy
            items={['industries', 'use_cases', 'customer_stories']}
            currentClassName='scrollspyActive'
          >
            <li>
              <a href='#industries'>Industries</a>
            </li>
            <li>
              <a href='#use_cases'>Use Cases</a>
            </li>
            <li>
              <Link to='/blockchain'>Blockchain</Link>
            </li>
          </Scrollspy>
        </div>
      </div>

      <div id='industries'>
        <h1 className='heading my-5 w-75 mx-auto'>Industries</h1>

        <div className='w-75 mx-auto'>
          <Link to='/industries/healthcare'>
            <IndustryCards
              url='https://www.h2o.ai/wp-content/uploads/2018/08/healthcare.jpg'
              height='80'
              title='Healthcare'
              description='Saving Lives with AI'
            />
          </Link>
          <div className='industryCardDiv my-5'>
            <div className='div1' data-aos='fade-down'>
              <Link to='/industries/financial_services'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/08/financial_serives.jpg'
                  height='50'
                  title='Financial Services'
                  description='Improving Customer Experiences and Decreasing Fraud and with AI'
                />
              </Link>
            </div>
            <div className='div2' data-aos='fade-down'>
              <Link to='/industries/insurance'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/08/insurance.jpg'
                  height='50'
                  title='Insurance'
                  description='Personalizing Customer Experiences with AI'
                />
              </Link>
            </div>
          </div>
          <div className='industryCardDiv my-5'>
            <div className='div1' data-aos='fade-down'>
              <Link to='/industries/marketing'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/08/marketing.jpg'
                  height='50'
                  title='Marketing'
                  description='Delivering Targeted Marketing with AI'
                />
              </Link>
            </div>
            <div className='div2' data-aos='fade-down'>
              <Link to='/industries/telecommunications'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/08/telecummunications.jpg'
                  height='50'
                  title='Telecommunications'
                  description='Improving Operations with AI'
                />
              </Link>
            </div>
          </div>
          <div className='industryCardDiv my-5'>
            <div className='div1' data-aos='fade-down'>
              <Link to='/industries/manufacturing'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Maintence.jpg'
                  height='50'
                  title='Manufacturing'
                  description='Optimizing Processes with AI'
                />
              </Link>
            </div>
            <div className='div2' data-aos='fade-down'>
              <Link to='/industries/retail'>
                <IndustryCards
                  url='https://www.h2o.ai/wp-content/uploads/2018/07/Know_Your_Customer-1.jpg'
                  height='50'
                  title='Retail'
                  description='Streamlining Operations and Personalizing Experiences with AI'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Benefits />
      <div id='use_cases' className='bg-light'>
        <h1 className='heading my-5 w-75 mx-auto'>Use Cases</h1>
        <div className='w-75 mx-auto'>
          <div className='useCaseTab'>
            <div className='tabPanel' data-aos='fade-right'>
              <div
                className={`panel ${activeTab === 1 ? 'active' : null}`}
                onClick={() => setActiveTab(1)}
              >
                Finance
              </div>
              <div
                className={`panel ${activeTab === 2 ? 'active' : null}`}
                onClick={() => setActiveTab(2)}
              >
                Insurance
              </div>
              <div
                className={`panel ${activeTab === 3 ? 'active' : null}`}
                onClick={() => setActiveTab(3)}
              >
                Healthcare
              </div>
              <div
                className={`panel ${activeTab === 4 ? 'active' : null}`}
                onClick={() => setActiveTab(4)}
              >
                Marketing
              </div>
              <div
                className={`panel ${activeTab === 5 ? 'active' : null}`}
                onClick={() => setActiveTab(5)}
              >
                Retail
              </div>
              <div
                className={`panel ${activeTab === 6 ? 'active' : null}`}
                onClick={() => setActiveTab(6)}
              >
                Telecom
              </div>
              <div
                className={`panel ${activeTab === 7 ? 'active' : null}`}
                onClick={() => setActiveTab(7)}
              >
                Manufacturing
              </div>
            </div>
          </div>

          <div className='collectionContainer pb-5'>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 1 ? 'flex' : 'none' }}
            >
              {financeCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 2 ? 'flex' : 'none' }}
            >
              {insuranceCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 3 ? 'flex' : 'none' }}
            >
              {healthcareCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 4 ? 'flex' : 'none' }}
            >
              {marketingCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 5 ? 'flex' : 'none' }}
            >
              {retailCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 6 ? 'flex' : 'none' }}
            >
              {telecomCard}
            </div>
            <div
              className='collection mt-5'
              style={{ display: activeTab === 7 ? 'flex' : 'none' }}
            >
              {manufacturingCard}
            </div>
          </div>
        </div>
      </div>

      <div
        className=' bg-blue pt-3 text-center text-light'
        id='customer_stories'
      >
        <h1 className='heading my-5 w-75 mx-auto text-light text-center'>
          Our Clients
        </h1>
        <div>
          <img src={pic1} className='m-3 p-3' style={{ maxHeight: '200px' }} />
          <img src={pic5} className='m-3 p-3' style={{ maxHeight: '200px' }} />
          <br />
          <img
            src={pic2}
            style={{ transform: 'scale(0.75)' }}
            className='m-3 p-3'
            style={{ maxHeight: '140px' }}
          />
          <img src={pic3} className='m-3 p-3' style={{ maxHeight: '160px' }} />
          <img src={pic4} className='m-3 p-3' style={{ maxHeight: '160px' }} />
        </div>
      </div>

      <section className='contact bg-dark p-5' id='testimonials'>
        <div className='container'>
          <div className='colorlib-narrow-content'>
            <div className='animate-box' style={{ textAlign: 'center' }}>
              <p className='h3 text-uppercase text-primary'>Get in Touch</p>
              <br />
              <br />
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
              <div className='col-md-5 d-flex justify-content-center flex-column'>
                {/* <iframe src={ require("http://maps.google.com/maps?q=12.9779083,77.6396974&z=15&output=embed")} width="400" height="300" frameborder="0" style={{border:0,marginRight: 10}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                <div style={{ margin: 'auto' }}>
                  <Iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9051719433132!2d77.6396944!3d12.9779167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDU4JzQwLjUiTiA3N8KwMzgnMjIuOSJF!5e0!3m2!1sen!2sin!4v1591103125788!5m2!1sen!2sin'
                    width='350'
                    height='370'
                    frameborder='0'
                    style='border:0;'
                    allowfullscreen=''
                    aria-hidden='false'
                    tabindex='0'
                  ></Iframe>
                </div>
                <br />
                <br />
              </div>
              <div className='col-md-7 col-md-push-1 rounded-lg '>
                <div className='row rounded-lg'>
                  <div className='col-md-10 col-md-offset-1 col-md-pull-1 animate-box'>
                    <form
                      id='fs-frm'
                      name='simple-contact-form'
                      acceptCharset='utf-8'
                      action='https://formspree.io/d3dileep@gmail.com'
                      method='post'
                    >
                      <fieldset id='fs-frm-inputs'>
                        <div className='form-group'>
                          <input
                            type='text'
                            name='name'
                            id='full-name'
                            className='form-control'
                            placeholder='Name'
                            required=''
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            type='email'
                            name='_replyto'
                            id='email-address'
                            className='form-control'
                            placeholder='Email'
                            required=''
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            name='sub'
                            className='form-control'
                            placeholder='Subject'
                          />
                        </div>
                        <div className='form-group'>
                          <textarea
                            name='message'
                            id='message'
                            required=''
                            cols='30'
                            rows='7'
                            className='form-control'
                            placeholder='Message'
                          ></textarea>
                        </div>
                        <input
                          type='hidden'
                          name='_subject'
                          id='email-subject'
                          value='Contact Form Submission'
                        />
                      </fieldset>
                      <div className='form-group'>
                        <input
                          type='submit'
                          className='btn btn-primary btn-send-message'
                          name='submit'
                          value='Send Message'
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </section>
    </div>
  )
}

export default Main
