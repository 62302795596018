import React, { useEffect } from 'react';
import "./IndustryCards.css"
const IndustryCards = (props) => {



    return (
        <div className="industryCard" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${props.url})`, height: `${props.height}vh`, width: '100%' }}>
            <div className="title">
                {props.title}
            </div>
            <div className="overlay text-light">
                <div className="overlay-text text-center">
                    <h3>{props.title}</h3>
                    <hr style={{ backgroundColor: 'rgb(255,229,43)', height: '4px', borderRadius: '10px' }} />
                    <h5>{props.description}</h5>
                    <b>Learn More &#62;</b>

                </div>
            </div>
        </div>
    );
}

export default IndustryCards;