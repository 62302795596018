import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <footer
      className='with-pattern-1 position-relative p-5'
      style={{ backgroundColor: '#1b3152' }}
    >
      <div className='container-fluid section-padding-y'>
        <div className='row justify-content-around'>
          <div className='col-md-4 my-4 mb-lg-0 text-light'>
            {' '}
            <h4>Chattel</h4> <br />
            <p style={{ fontSize: '1.3em', color: 'rgb(208,214,171)' }}>
              With the passing decade, world market will be virtual and bots
              will do the business. Are you equipped with latest technological
              tool?
            </p>
          </div>
          <div className='col-md-2 mb-2 mb-lg-0'>
            <h2 className='h5 my-4 text-light'>Quick Links</h2>
            <div className='d-flex'>
              <ul className='list-unstyled d-inline-block mr-4 mb-0'>
                <li className='mb-2'>
                  <a
                    style={{ color: 'rgb(208,214,171)' }}
                    className='links'
                    href='#benefits'
                  >
                    About us
                  </a>
                </li>
                <li className='mb-2'>
                  <a
                    style={{ color: 'rgb(208,214,171)' }}
                    className='links'
                    href='#testimonials'
                  >
                    Contact us
                  </a>
                </li>
                <li className='mb-2'>
                  <a
                    style={{ color: 'rgb(208,214,171)' }}
                    className='links'
                    href='#use_cases'
                  >
                    Products
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-2 mb-2 mb-lg-0'>
            <h2 className='h5 my-4 text-light'>Services</h2>
            <div className='d-flex'>
              <ul className='list-unstyled mr-4 mb-0'>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/healthcare'>
                      <span style={{ color: 'rgb(208,214,171)' }}>
                        Healthcare
                      </span>
                    </Link>
                  </span>
                </li>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/insurance'>
                      <span style={{ color: 'rgb(208,214,171)' }}>
                        Insurance
                      </span>
                    </Link>
                  </span>
                </li>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/financial_services'>
                      <span style={{ color: 'rgb(208,214,171)' }}>Finance</span>
                    </Link>
                  </span>
                </li>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/marketing'>
                      {' '}
                      <span style={{ color: 'rgb(208,214,171)' }}>
                        Marketing
                      </span>
                    </Link>
                  </span>
                </li>

                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/telecommunications'>
                      {' '}
                      <span style={{ color: 'rgb(208,214,171)' }}>
                        Telecommunication
                      </span>
                    </Link>
                  </span>
                </li>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/manufacturing'>
                      {' '}
                      <span style={{ color: 'rgb(208,214,171)' }}>
                        Manufacturing
                      </span>
                    </Link>
                  </span>
                </li>
                <li className='mb-2'>
                  <span className='links'>
                    <Link to='/industries/retail'>
                      {' '}
                      <span style={{ color: 'rgb(208,214,171)' }}>Retail</span>
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-3'>
            <h2 className='h5 my-4 text-light'>Contact Info</h2>
            <ul className='list-unstyled mr-4 mb-3'>
              <li className='mb-2'>
                <p className='text-primary' style={{ fontWeight: '550' }}>
                  Chattel Technologies
                </p>
                <p style={{ color: 'rgb(208,214,171)' }}>
                  39, NGEF Ln, Binnamangala, 1st Stage, Indiranagar, Bengaluru,
                  Karnataka-560038, India
                </p>
              </li>
              <li className='mb-2'>
                <a
                  style={{ color: 'rgb(208,214,171)' }}
                  href='mailto:  info@chattel.in'
                >
                  <i class='fa fa-envelope'></i> info@chattel.in
                </a>
              </li>
            </ul>
            <ul className='list-inline '>
              <li className='list-inline-item'>
                <a className='social-link' href='#'>
                  <i className='fab fa-facebook-f'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a className='social-link' href='#'>
                  <i className='fab fa-twitter'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a className='social-link' href='#'>
                  <i className='fab fa-google-plus'></i>
                </a>
              </li>
              <li className='list-inline-item'>
                <a className='social-link' href='#'>
                  <i className='fab fa-instagram'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
