import React, { useState } from 'react'
import './benefits.css'

const Benefits = () => {
  const [style, setStyle] = useState({ display: 'none' })
  const [style1, setStyle1] = useState({ display: 'none' })
  const [style2, setStyle2] = useState({ display: 'none' })
  const [style3, setStyle3] = useState({ display: 'none' })
  const [style4, setStyle4] = useState({ display: 'none' })
  const [style5, setStyle5] = useState({ display: 'none' })
  return (
    <section
      className='button-tiles '
      id="benefits"
      style={{
        backgroundImage:
          'url(' +
          'https://www.h2o.ai/wp-content/uploads/2021/01/mesh-2.png' +
          ')',
      }}
    >
      <div className='button-tile-content' data-aos='fade-down'>
        <div className='container text-center'>
          <h2 className='wow wefadeInUp '>Chattel AI Hybrid Cloud</h2>{' '}
          <h5 className='wow wefadeInUp'>
            One Platform. Thousands of Use Cases.
          </h5>
          <div className='tiles desktop_tiles'>
            <div className='col-outer'>
              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/prepare-1.png' />
                </div>
                <div className='tileTitle text-center'>PREPARE</div>

                <div className='hoverText' style={style}>
                  <div className='hoverTitle'>
                    PREPARE
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/prepare_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      Full suite of data preparation, data engineering, data
                      labeling, and automatic feature engineering tools to
                      accelerate time to insight.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle1({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle1({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/model.png' />
                </div>
                <div className='tileTitle text-center'>MODEL</div>

                <div className='hoverText' style={style1}>
                  <div className='hoverTitle'>
                    MODEL
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/model_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      Award-winning Automatic Machine Learning (AutoML)
                      technology to solve the most challenging problems,
                      including Time Series and Natural Language Processing.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle2({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle2({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/explain.png' />
                </div>
                <div className='tileTitle text-center'>EXPLAIN</div>

                <div className='hoverText' style={style2}>
                  <div className='hoverTitle'>
                    EXPLAIN
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/explain_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      Industry-leading toolkit of explainable and responsible AI
                      methods to combat bias and increase transparency into
                      machine learning models.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle3({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle3({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/operate.png' />
                </div>
                <div className='tileTitle text-center'>OPERATE</div>
                <div className='hoverText' style={style3}>
                  <div className='hoverTitle'>
                    OPERATE
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/operate_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      Deploy models in any environment and enable drift
                      detection, automatic retraining, custom alerts, and
                      real-time monitoring.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle4({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle4({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/develop.png' />
                </div>
                <div className='tileTitle text-center'>DEVELOP</div>

                <div className='hoverText' style={style4}>
                  <div className='hoverTitle'>
                    DEVELOP
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/develop_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      H2O Wave enables fast development of AI applications
                      through an open-source, light-weight Python development
                      framework.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tile-col'
                onMouseEnter={(e) => {
                  setStyle5({ display: 'block' })
                }}
                onMouseLeave={(e) => {
                  setStyle5({ display: 'none' })
                }}
              >
                <div className='tileicon'>
                  <img src='https://www.h2o.ai/wp-content/uploads/2021/01/consume.png' />
                </div>
                <div className='tileTitle text-center'>CONSUME</div>

                <div className='hoverText' style={style5}>
                  <div className='hoverTitle'>
                    CONSUME
                    <img
                      src='https://www.h2o.ai/wp-content/uploads/2021/01/consume_hover.png'
                      className='hoverIcon'
                    />
                  </div>
                  <div className='hoverContent'>
                    <p>
                      H2O AI Hybrid Cloud enables data science teams to quickly
                      share their applications with team members and business
                      users, encouraging company-wide adoption.
                    </p>
                  </div>
                  <div className='hoverCTA'>
                    <p>
                      <a target=''>Learn More</a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='lower_section' data-aos='fade-down'>
            <div className='col-outer'>
              <h2 className='text-center'>Benefits</h2>
              <div className='col-four'>
                <h6 className='underline-yellow blocks text-center'>
                  Explainable World-className Models{' '}
                </h6>
              </div>
              <div className='col-four'>
                <h6 className='underline-yellow blocks text-center'>
                  Any Cloud or On-Premises Infrastructure{' '}
                </h6>
              </div>
              <div className='col-four'>
                <h6 className='underline-yellow blocks text-center'>
                  High Performance and Scalable{' '}
                </h6>
              </div>
              <div className='col-four'>
                <h6 className='underline-yellow blocks text-center'>
                  Extensible and Customizable{' '}
                </h6>
              </div>
            </div>

            <div className='text-center lower_content'>
              <h6>Explore the H2O AI Hybrid Cloud</h6>{' '}
              <a target='' className='btn-yellow text-center'>
                <span className='text'>Learn More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Benefits
