import React, { useEffect, useState } from 'react';
import './DumbCards.css'
import { Redirect } from 'react-router-dom'
import AOS from 'aos'
import "aos/dist/aos.css";

const convertToRoute = (title) => title.toLowerCase().split(' ').join('-')

const DumbCards = (props) => {
    const [redirect, setRedirect] = useState(false)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    if (redirect) return <Redirect to={`/usecases/${convertToRoute(props.title)}`} />
    return (
        <div data-aos="fade-right" className={`DumbCard my-3 ${props.margin === true ? "margin" : null}`} style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${props.image})` }} onClick={() => setRedirect(true)}>
            <div className="dumbcardText">
                {props.title}
            </div>
        </div>

    );
}

export default DumbCards;