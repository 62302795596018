import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Main from './Pages/Main/Main'
import Industries from './Pages/Industries/Industries'
import Usecases from './Pages/UseCases/Usecase';
import Blockchain from './Pages/Blockchain/Blockchain';
import Footer from './Components/Footer'
import Navbar from './Components/Navbar'
function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/blockchain" exact component={Blockchain} />
          <Route path="/industries/:industry" component={Industries} />
          <Route path="/usecases/:case" component={Usecases} />
        </Switch>
        <Footer />
      </div>

    </Router>
  );
}

export default App;
