import React, { useState, useEffect } from 'react'
import './Industries.css'
import { Redirect } from 'react-router-dom'
import Finance from './finance.png'
import Insurance from './insurance.png'
import Manufacturing from './manufacturing.png'
import Marketing from './marketing.png'
import Retail from './retail.png'
import Telecom from './telecom.png'
import Healthcare from './healthcare.png'
import Areas from './Areas.png'
import IndustryCards from '../../Components/IndustryCards/IndustryCards'
const insuranceOverview = <h4 style={{ fontWeight: '200' }}>
    For insurance companies finding and building customer relationships and managing risks are key to creating a growing, profitable business. Companies that are making extensive use of AI are reaping the benefits of increased customer satisfaction and loyalty while decreasing fraud which adds to their bottom line. These companies are using AI for a number of scenarios including risk management, fraud detection, customer retention, and optimized marketing. Chattel Tech, the open source and automation leader in AI, is empowering leading insurance companies to deliver AI solutions that are changing the industry.
</h4>
const financeOverview = <h4 style={{ fontWeight: '200' }}>
    In financial services the competition for customer share of wallet is intense with firms looking for every advantage in marketing while fighting fraud, money laundering and other issues. Companies that are making extensive use of AI are reaping the benefits of increased customer satisfaction and loyalty, decreased fraud, and reduced regulatory penalties which adds to their bottom line.
    <br /><br />
These companies are using AI for a number of scenarios including anti-money laundering, fraud detection, credit risk scoring, and churn prediction. Chattel Tech, the open source and automation leader in AI, is empowering leading financial services companies to deliver AI solutions that are changing the industry.
</h4>
const marketingOverview = <h4 style={{ fontWeight: '200' }}>
    For marketing teams and marketing technology firms, finding and cultivating valuable customers is the key to building a profitable business. Leading companies know that personalized customer experiences and streamlined operations are key to their success. Companies that are making extensive use of AI are reaping the benefits of increased customer satisfaction and loyalty while improving operations which adds to their bottom line.
</h4>
const retailOverview = <h4 style={{ fontWeight: '200' }}>
    The retail industry is highly competitive with increasing pressure form online competitors. Leading retailers know that customer experience and streamlined operations are key to their success. Companies that are making extensive use of AI are reaping the benefits of increased customer satisfaction and loyalty while decreasing fraud and improving operations which adds to their bottom line. These companies are using AI for a number of scenarios including assortment optimization, product recommendations, fraud detection, customer retention, and optimized marketing. Chattel Tech, the open source and automation leader in AI, is empowering leading retail companies to deliver AI solutions that are changing the industry.
</h4>
const telecommunicationOverview = <h4 style={{ fontWeight: '200' }}>
    For telecommunications companies, finding and building customer relationships are key to creating a growing, profitable business. Leading companies know that customer experience and streamlined operations are key to their success. Companies that are making extensive use of AI are reaping the benefits of increased customer satisfaction and loyalty while decreasing fraud and improving operations which adds to their bottom line.
<br /><br />
These companies are using AI for a number of scenarios including predictive customer support, fleet management, fraud detection, customer retention, and optimized marketing. Chattel Tech, the open source and automation leader in AI, is empowering leading telecommunications companies to deliver AI solutions that are changing the industry.
</h4>
const manufacturingOverview = <h4 style={{ fontWeight: '200' }}>
    Manufacturers face mounting pressure to decrease costs while continuing to deliver high quality products and service. Leading manufacturers are taking a proactive approach to streamline operations. Companies that are making extensive use of AI are reaping the benefits of improved efficiency, decreased downtime while increasing customer satisfaction.
<br /><br />
These companies are using AI for a number of scenarios including predictive maintenance, predictive process design, supply chain optimization and more. Chattel Tech, the open source and automation leader in AI, is empowering leading manufacturing companies to deliver AI solutions that are changing the industry.
</h4>
const healthcareOverview = <h4 style={{ fontWeight: '200' }}>

    The healthcare and life sciences industries are evolving rapidly, with organizations implementing the use of new technologies to improve and streamline capabilities. Chattel Tech is an Artificial Intelligence and Machine Learning company that is co-innovating with major healthcare organizations to address new challenges. With large volumes of data, increasing complexities in cost and patient outcomes, and the current global pandemic, Chattel Tech is a partner enabling the transformational needs of providers, pharma, and patients.
<br /><br />

In order to provide cost-effective and high-impact care, Chattel Tech end-to-end platform enables organizations to rapidly build world-class AI models and applications in the cloud or on premise to deliver lasting impact for users across research, clinical, operational, and financial dimensions.
</h4>

const healthUsecases = [
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-3.jpeg',
        title: 'Predicting ICU Transfers',
        desc: 'Saving lives by catching patients before the crash with AI'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-7.jpeg',
        title: 'Medical Testing',
        desc: 'Predicting Medical Test Results with AI'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-13.jpeg',
        title: 'Improving Clinical Workflow',
        desc: 'AI provides a Helping Hand to Clinicians'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-9.jpeg',
        title: 'Claims Fraud Detection',
        desc: 'Using AI to Stop $100 Billion in Fraudulent Healthcare Claims'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-5.jpeg',
        title: 'Claim Denials Management',
        desc: 'Streamlining the Claims Process with AI'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-2.jpeg',
        title: 'Predicting Hospital Acquired Infections (HAIs)',
        desc: 'Saving Lives with AI'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-10.jpeg',
        title: 'Predicting Hospital Readmissions',
        desc: 'Preventing Readmissions and Saving Lives with AI'
    },
    {
        image: 'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-11.jpeg',
        title: 'Sepsis Prevention',
        desc: '80% of Sepsis Deaths Can Be Prevented with Rapid Diagnosis'
    }
]
const financeUseCases = [
    {
        title: 'Customer Churn Prediction',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/Customer_Churn_Prediction-1.jpg',
        desc: 'AI Helps Retain Valuable Banking Customers'
    },
    {
        title: 'Credit Risk Scoring',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/Credit_Risk_Scoring-1.jpg',
        desc: 'Personalizing Credit Decisions with AI'
    },
    {
        title: 'Anti-Money Laundering',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/Anti_Money_Laundring-1.jpg',
        desc: 'Stopping Crime with AI.'
    },
    {
        title: 'Know Your Customer/Client (KYC)',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/Know_Your_Customer-1.jpg',
        desc: 'Understanding the dynamics of your customer interaction with AI'
    },
    {
        title: 'Fraud Detection',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Fraud_Detection-1.jpg',
        desc: 'Stopping Fraud in its Tracks with AI'
    },
    {
        title: 'Anomaly Detection',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Anomaly_Detection-1.jpg',
        desc: 'Finding Network Anomalies Faster with AI'
    }
]
const insuranceUseCases = [
    {
        title: 'Fraud Mitigation',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Fraud_Mitigation-1.jpg',
        desc: 'Finding and Stopping Fraud with AI'
    },
    {
        title: 'Customer Retention',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Customer_Retention-1.jpg',
        desc: 'AI Helps Retain Valuable Insurance Customers'
    },
    {
        title: 'Personalized Rate Management',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/use_cases_1.jpg',
        desc: 'Customizing Rates based on Individual Data using AI'
    },
    {
        title: 'Personalized Product Bundling',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Personalized_Product_Bundling-1.jpg',
        desc: 'Bundling the Right Product with AI'
    },
    {
        title: 'Claims Management',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/use_cases_5.jpg',
        desc: 'Streamlining the Claims Process with AI'
    }
]
const marketingUseCases = [
    {
        title: 'Lead Scoring',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Lead_Scoring-1.jpg',
        desc: 'Working the Right Leads with AI'
    },
    {
        title: 'Next Best Offer',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Offer-1.jpg',
        desc: 'The Right Offer at the Right Time with AI'
    },
    {
        title: 'Smart Segmentation',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Smart_Segmentation-1.jpg',
        desc: 'Understanding Customer Microsegments with AI'
    },
    {
        title: 'Content Personalization',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Content_Personalization-1.jpg',
        desc: 'AI Drives Relevant Dialog with Customers'
    },
    {
        title: 'Next Best Action',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Next_Best_Action-1.jpg',
        desc: 'The Right Offer at the Right Time with AI'
    }
]
const telecomUseCases = [
    {
        title: 'Predictive Customer Support',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Customer_Support-1.jpg',
        desc: 'Solving Customer Issues on the First Call'
    },
    {
        title: 'Predictive Fleet Maintenance',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Fleet_Maintenance-1.jpg',
        desc: 'Preventing Vehicle Downtime with AI'
    }
]
const manufacturingUseCases = [
    {
        title: 'Predictive Manufacturing Design',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Manufacturing_Design-1.jpg',
        desc: 'Finding Optimal Manufacturing Solutions with AI'
    },
    {
        title: 'Predictive Maintenance',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Predictive_Maintence.jpg',
        desc: 'Fixing Machinery Before a Breakdown with AI'
    },
    {
        title: 'Supply Chain Optimization',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Supply_Chain_Optimization-2.jpg',
        desc: 'Finding the best mix across the supply chain'
    },
    {
        title: 'Transportation Optimization',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/07/Transportation-Optimization.png',
        desc: 'Ensuring optimal quality of products during transportation and delivery'
    }
]
const retailUseCases = [
    {
        title: 'Product Recommendations',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Product_Recommendations-1.jpg',
        desc: 'Increasing Cross-sell and Upsell with AI'
    },
    {
        title: 'Assortment Optimization',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/08/Assortment_Optimization-1.jpg',
        desc: 'Stocking the Right Products to Meet Demand with AI'
    },
    {
        title: 'Pricing Optimization',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/use_cases_10.jpg',
        desc: 'Maximizing Profits with AI'
    }
]
const financialVideos = [
    {
        link: 'https://youtu.be/yFTTT3xewOw',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/02/190205_JKM_H2Oai-16508-1-540x330.jpg',
        paragraph: '"Managing machine learning model risk is of the utmost importance in heavily regulated industries such as finance; in particular, to manage potential risks due to bias/fairness, conceptual soundness, implementation, and model change control"',
        speaker: {
            name: 'Agus Sudjianto',
            occupation: 'EVP, Head of Corporate Model Risk, Wells Fargo'
        }
    },
    {
        link: 'https://youtu.be/otq2nQUSV3s',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/08/Ruben-Diaz-540x330.jpg',
        paragraph: `"The automation of the data science process reduced time and costs. And time is money. So, you can do more with the same amount of time. It's possible to deliver more value to the business, develop more use cases and focus the data science effort in the use case instead of development tasks."`,
        speaker: {
            name: 'Ruben Diaz',
            occupation: 'Data Scientist, Vision Banco, Vision Banco'
        }
    },
    {
        link: 'https://youtu.be/5bnNOjj_i8Y',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/01/radfa-540x330.png',
        paragraph: `"When it comes to developing artificial intelligence products the deployment is where most platforms fall short and with Chattel Tech we've got the mojo implementation that essentially simplifies that to the point of being a java object that you incorporate to your platform. That's fantastic!"`,
        speaker: {
            name: 'Rafa Garcia-Navarro',
            occupation: 'Co-Founder, CEO, and Chief Data Scientist , Ducit.ai'
        }
    }
]
const insuranceVideos = [
    {
        link: 'https://youtu.be/LFVIGMMlfhI',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/01/paypal_collision_fraud_detection-443x330.jpg',
        paragraph: '"AI and ML touch every point of the insurance value chain and are value enablers."',
        speaker: {
            name: 'Chris Madsen',
            occupation: 'Co-founder and CEO, Aegon Blue Square Re'
        }
    },
    {
        link: 'https://youtu.be/RI6CCA8rw-0',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/conor-540x330.png',
        paragraph: '"The ability to do advanced analytics and do more work across the data is going to be the differentiator for insurance companies going forward."',
        speaker: {
            name: 'Conner Jensen',
            occupation: 'Analytics Program Director, Zurich Insurance'
        }
    }
]
const retailVideos = [
    {
        link: 'https://youtu.be/ZAnNx5VjFyM',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/ncs-540x330.png',
        paragraph: '"Chattel Tech allowed us to interface directly with our existing application and it scales for our massive data set. There is nothing else like it."',
        speaker: {
            name: 'Satya Satyamoorthy',
            occupation: 'Director of Software Development, Nielsen Catalina Solutions'
        }
    },
    {
        link: 'https://youtu.be/DBuTgvOYfoo',
        image: '/wp-content/themes/h2o2018/templates/dist/images/product-details/case_study_2.jpg',
        paragraph: '"With Chattel Tech we are able to build models quickly so we can find patterns that we can use right away"',
        speaker: {
            name: 'Daqing Zhao',
            occupation: "Director, Advanced Analytics, Macy's"
        }
    }
]
const telecomVideos = [
    {
        link: 'https://youtu.be/1KQTTxMqelg',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/03/190205_JKM_H2Oai-17082-540x330.jpg',
        paragraph: `"AI means many things to many people and it's also in a hype cycle. People want some very quick results with significant advantages. From our advantage point, when we look at AI we see it as a mix of technologies. (...) So when we look at a POC, our angle of conversation is whether we can provide a quick ROI by doing the POC. It's a three-step process that we do as a company for a quick return on investment. But for any other customer, one is the data prep. Second is Driverless Ai, which is where we have a significant value."`,
        speaker: {
            name: 'Rahul Bhuman',
            occupation: 'Vice President, Tech Mahindra'
        }
    },
    {
        link: 'https://youtu.be/9MNPrLsYUdc',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/Comcast.jpg',
        paragraph: '"We reached 90% accuracy in the real-time deployment and our results were in-sync with the training phase, which was really good."',
        speaker: {
            name: 'Bhavana Bhasker, et al.',
            occupation: 'Data Scientist, Comcast'
        }
    }
]
const manufacturingVideos = [
    {
        link: 'https://www.youtube.com/watch?v=iaE-KHNosiI&feature=youtu.be',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/04/Wei-Shao-Hortifrut-540x330.png',
        paragraph: '"I am very excited to use the Chattel Tech Driverless AI because prior to it, we used to spend weeks hyperparameter tuning etc., but with Driverless AI, one experiment takes just a few hours."',
        speaker: { name: 'Wei Shao', occupation: 'Data Scientist, Hortifrut' }
    },
    {
        link: 'https://youtu.be/vYywPBjxH7E',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/01/Ed-Dixon-Data-Scientist-Intel-443x509-443x330.png',
        paragraph: '"Once we started using Chattel Tech we saw the number of false positives reduced significantly within hours."',
        speaker: { name: 'Ed Dixon', occupation: 'Data Scientist, Intel' }
    }
]
const marketingVideos = [
    {
        link: 'https://youtu.be/f4b2Yoe9JEs',
        image: 'https://www.h2o.ai/wp-content/uploads/2018/07/190205_JKM_H2Oai-17048-540x330.jpg',
        paragraph: '"AI to do AI is absolutely a watershed moment in our industry."',
        speaker: { name: 'Martin Stein', occupation: 'Chief Product Officer, G5' }
    },
    {
        link: 'https://youtu.be/A5EbRgZ5UsM',
        image: 'https://www.h2o.ai/wp-content/uploads/2019/12/scott-pete-2-540x330.png',
        paragraph: `"With Chattel Tech Driverless AI, we have seen improvements in the models and the results we were able to deliver for our clients. In one case we saw a 700% 
  increase in cost savings, for a campaign costs with an 11% improvement in overall ROI. From an internal perspective, we've seen a reduction in our model development times significantly. Driverless AI allows us to tune models faster and gain insights out of the entire process. "`,
        speaker: {
            name: 'Scott Pete',
            occupation: 'Director of Analytics and Insights , AIMIA'
        }
    }
]





const Industries = (props) => {
    const [industry, setIndustry] = useState('');
    const [titles, setTitle] = useState({});
    const [overview, setOverview] = useState('');
    const [image, setImage] = useState('')
    const [useCases, setUseCases] = useState([])
    const [video, setVideo] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        setIndustry(props.match.params.industry);
        switch (industry) {
            case 'financial_services':
                setOverview(financeOverview)
                setImage(Finance)
                setUseCases(financeUseCases)
                setVideo(financialVideos)
                return setTitle({ title: "Financial Services", subtitle: "Finance" })
            case 'marketing':
                setOverview(marketingOverview)
                setImage(Marketing)
                setUseCases(marketingUseCases)
                setVideo(marketingVideos)
                return setTitle({ title: "Marketing", subtitle: "Marketing" })
            case 'retail':
                setImage(Retail)
                setOverview(retailOverview)
                setUseCases(retailUseCases)
                setVideo(retailVideos)
                return setTitle({ title: "Retail", subtitle: "Retail" })
            case 'healthcare':
                setImage(Healthcare)
                setOverview(healthcareOverview)
                setUseCases(healthUsecases)
                setVideo([])
                return setTitle({ title: "Healthcare", subtitle: "Healthcare" })
            case 'telecommunications':
                setImage(Telecom)
                setOverview(telecommunicationOverview)
                setUseCases(telecomUseCases)
                setVideo(telecomVideos)
                return setTitle({ title: "Telecommunication", subtitle: "Telecommunication" })
            case 'insurance':
                setImage(Insurance)
                setOverview(insuranceOverview)
                setUseCases(insuranceUseCases)
                setVideo(insuranceVideos)
                return setTitle({ title: "Insurance", subtitle: "Insurance" })
            case 'manufacturing':
                setImage(Manufacturing)
                setOverview(manufacturingOverview)
                setUseCases(manufacturingUseCases)
                setVideo(manufacturingVideos)
                return setTitle({ title: "Manufacturing", subtitle: "Manufacturing" })
            default:
                return <Redirect to="/" />

        }
    }, [industry, props]);


    const useCasesComponents = useCases.map((i, k) => {
        if (k % 2 === 0) {
            return <div key={k} className="my-3" style={{ width: '47.5%', marginRight: '2.5%' }}>
                <IndustryCards title={i.title} url={i.image} description={i.desc} height={40} />
            </div>
        }
        else {
            return <div key={k} className="my-3" style={{ width: '47.5%', marginLeft: '2.5%' }}>
                <IndustryCards title={i.title} url={i.image} description={i.desc} height={40} />
            </div>
        }
    })
    const videoComponents = video.map((i, k) => {
        if (k % 2 === 0) {
            return <div style={{ display: 'flex' }} className="my-5" key={k}>
                <div className="videoHolder" style={{ backgroundImage: `url(${i.image})`, width: '47.5%', height: '50vh', backgroundSize: 'cover', cursor: 'pointer' }} onClick={() => window.open(i.link)}>
                </div>
                <div className="video-text-continer" style={{ width: '47.5%', height: '50vh', marginLeft: '5%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1 }}></div>
                    <div>
                        <h6>{i.speaker.name}</h6>
                        <h6>{i.speaker.occupation}</h6>
                        <p className="my-4">{i.paragraph}</p>
                    </div>
                </div>
            </div>
        }
        else {
            return <div style={{ display: 'flex' }} className="my-5" key={k}>
                <div className="video-text-continer" style={{ width: '47.5%', height: '50vh', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ flex: 1 }}></div>
                    <div>
                        <h6>{i.speaker.name}</h6>
                        <h6>{i.speaker.occupation}</h6>
                        <p className="my-4">{i.paragraph}</p>
                    </div>
                </div>
                <div className="videoHolder" style={{ backgroundImage: `url(${i.image})`, width: '47.5%', height: '50vh', backgroundSize: 'cover', marginLeft: '5%', cursor: 'pointer' }} onClick={() => window.open(i.link)}>
                </div>

            </div >
        }
    })


    return (
        <div>
            <div className="industriesBanner">
                <div className="w-75 mx-auto">
                    <div className='display-4' style={{ fontWeight: 'bold' }}>
                        {titles.title}
                    </div>
                    <h2 style={{ fontWeight: '400' }}>AI for {titles.subtitle}</h2>
                </div>
            </div>

            <div className="w-75 mx-auto my-5">
                <h5 className="text-center my-3">
                    <span className="p-1" style={{ borderBottom: '4px solid rgb(255,229,43)' }}>Overview</span>
                </h5>
                <h1 className="text-center my-4">AI Transformation in {titles.subtitle}</h1>
                <div className="w-75 mx-auto text-center">
                    {overview}
                </div>
                <div className="my-5 py-5 text-center display-4" style={{ fontWeight: 'bold' }}>Use-Cases and Customers</div>
                <div>
                    <img src={image} alt="UseCases" width="100%" />
                </div>
            </div>
            <div className="useCasePanel mt-5 bg-light p-5">
                <h5 className="text-center my-5">
                    <span className="p-1 my-5" style={{ borderBottom: '4px solid rgb(255,229,43)' }}>Use Cases</span>
                </h5>
                <div className="w-75 mx-auto" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {useCasesComponents}
                </div>

            </div>
            <img src={Areas} alt="Areas" height="100%" />

            <div style={{ backgroundColor: 'white' }}>
                <div className="w-75 mx-auto my-5">
                    <h3 className='my-5'>Related Case Studies</h3>
                    <div className="video-panel">
                        {videoComponents}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Industries;