import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom'

const customerChurnPrediction = [
    'Customer Churn Prediction',
    'AI Helps Retain Valuable Banking Customers',
    'Some financial services customers become quite valuable as they generate fees on transactions and grow a portfolio of business over the years including banking fees, credit cards, home loans, personal loans and more. Simple churn analysis uses rules based on known behaviors to identify potential churn risks. Rules-based systems, however, are inflexible and miss many customers who do churn and generate false positives that end up giving expensive incentives to customers who were not at risk to leave the bank.',
    'AI is a great solution for customer churn prediction as the problem involves complex data over time and interactions between different customer behaviors that can be difficult for people to identify. AI can look at a variety of data, including new data sources, and at relatively complex interactions between behaviors and compared to individual history to determine risk. AI can also be used to recommend the best offer that will most likely retain a valuable customer. In addition, AI can identify the reasons why a customer is at risk and allow financial institution to act against those areas for the individual customer and more globally.'
]
const antiMoneyLaundering = [
    'Anti-Money Laundering',
    'Stopping Crime with AI.',
    'Money laundering is a huge problem for the financial services sector. According to the United Nations Office on Drugs and Crime, the estimated $2 trillion is “cleaned” through the banking system each year. Fines for banks who fail to stop money laundering have increased by 500X in the last decade to more than $10 Billion per year. As a result, banks have built large teams of people and given them the time-consuming task of finding and investigating suspicious transactions which often take the form of numerous small transfers within a complex network of players. Investigation teams have used rules-based systems to find suspicious transactions, but the rules quickly become outdated and produce large numbers of false positives that still need to be reviewed.',
    'AI, especially time series modeling, is particularly good at looking at series of complex transactions and finding anomalies. Anti-money laundering using machine learning techniques can find suspicious transactions and networks of transactions. These transactions are flagged for investigation and can be scored as high, medium or low priority so that the investigator can prioritize their efforts. The AI can also provide reason codes for the decision to flag the transaction. These reason code tell the investigator where they might look to uncover the issues and help to streamline the investigative process. AI can also learn from the investigators as they review and clear suspicious transactions and automatically reinforce the AI model’s understanding to avoid patterns that don’t lead to laundered money.'
]
const creditRiskScoring = [
    ' Credit Risk Scoring',
    'Personalizing Credit Decisions with AI',
    'Banks and credit card companies use credit scores to evaluate potential risk when lending money or providing credit. Traditional credit scoring uses a scorecard method which weights various factors including payment history, dept burden, length of credit history, types of credit used, and recent credit inquiries. This traditional method is based on broad segments and will deny credit to consumers without considering their current situation or other extenuating factors. Traditional methods may also give credit to consumers, called churners, who are “gaming the system” and taking out a large number of reward credit cards but are not profitable for the issuers. For credit decisions there is also the additional regulatory burden that banks and credit card companies must explain to the consumer why they have been denied credit.',
    'AI is a great solution for credit scoring using more data to provide an individualized credit score based on factors including current income, employment opportunity, recent credit history, and ability to earn in addition to older credit history. This more granular and individualized approach allows banks and credit card companies the ability to more accurately assess each borrower and allows them to provide credit to people who would have been denied under the scorecard system including people with income potential such as new college graduates or temporary foreign nationals. AI can also adapt to new problems, like credit card churners, who might have a high credit score, but are not likely to be profitable for the card issuer. AI can also satisfy regulatory requirements to provide reason codes for credit decisions that explain the key factors in credit decisions.'
]
const kyc = [
    'Know Your Customer/Client (KYC)',
    'Understanding the dynamics of your customer interaction with AI ',
    'Know Your Customer (KYC) is a key part of money laundering and anti-terrorism legislation. The Customer Due Diligence (CDD) process requires banks to file reports of suspicious activity. Almost two million such reports were filed in the United States alone in 2017 according to a study by the Royal United Services Institute     for Defence and Security Studies — a U.K.think tank.Failure to identify and file reports on suspicious transactions results in billions of dollars in fines for banks.Investigators looking into suspicious activity use a variety of tools including rules that flag frequent or international transactions or interactions with offshore financial centers.Unfortunately, with the volume and variety of transactions, rules - based approaches are not flexible enough to capture new patterns and produce large number of false positives that need to be reviewed.',
    'AI is an ideal technology for finding anomalous patterns and identifying areas of risk especially where there are a large number of items of different types that need to be reviewed and potentially correlated. Machine learning can be used to perform analysis of transactions and can look for indicators of suspicious behavior including transactions with dubious jurisdictions, suspicious companies or known parties. AI can also offer better insights into transactions through analysis of both structured and unstructured data. Natural Language Processing (NLP) techniques allow AI systems to search through communications to find additional signal including extracting metadata, identifying people or companies referenced, and categorizing the intent or purpose of the communication. All of these can help pinpoint suspicious transactions and help investigators as they investigate transactions.'
]
const fraudDetection = [
    'Fraud Detection',
    'Stopping Fraud in its Tracks with AI',
    'Fraud is a huge problem in the banking industry. In 2016, the top 10 fraud types including wire fraud, card fraud, and loan fraud accounted for $181 Billion in annual losses, and the numbers are only increasing, according to fraud expert Frank McKenna. Detecting and preventing fraud is a huge challenge for banks given the large variety of fraud types and the volume of transactions that need to be reviewed and manual or rules-based systems can’t keep up.',
    'AI can be used to analyze large volumes of transactions to find fraud patterns and then use those patterns to identify fraud as it happens in real-time. When fraud is suspected, AI models can be used to reject transactions outright or flag transactions for investigation and can even score the likelihood of fraud, so investigators can prioritize their work on the most promising cases. The AI model can also provide reason codes for the decision to flag the transaction. These reason codes tell the investigator where they might look to uncover the issues and help to streamline the investigative process. AI can also learn from the investigators as they review and clear suspicious transactions and automatically reinforce the AI model’s understanding to avoid patterns that don’t lead to fraudulent activities.'
]
const anomalyDetection =
    [
        'Anomaly Detection',
        'Finding Network Anomalies Faster with AI',
        'Mobile applications are critical to many businesses today. For credit card and banking companies, for example, mobile applications represent a significant channel of interaction where customer can review transactions, pay bills and resolve support issues. When application services are not available, customers use more expensive call centers for support. With payment applications, an outage means lost transactions,  revenues and increased customer churn.',
        'AI systems have been proven successful at detecting anomalies in transaction volume data. This time series process looks at expected data volumes based on historical patterns. Upper and lower boundaries are also predicted based on volume variation. This system is then used to compare real-time transaction value to expected volume. This real-time system allows network administrators to be notified when transactions start to spike above or fall below these boundaries so they can take action before an outage in service.'
    ]
const smartSegmentation =
    [
        'Smart Segmentation',
        'Understanding Customer Microsegments with AI',
        'Marketing teams often rely on a handful of simple marketer-defined segments to drive their marketing efforts. Unfortunately, what is easy for the marketer is not always best for the customer. Marketing content and offers are targeted at the large groups based on simple attributes like location or income, and most people in the group are not receptive to these marketing messages leading to low conversion rates, poor customer satisfaction and customer churn.',
        'AI based machine learning models can easily segment customers using a much wider variety of data including browsing behavior, prior purchases, demographics, household data from third parties and more. The outcome is much smaller groups of customers with clearly defined attributes that are customer-based including common characteristics, interest and intent. This information allows marketers to develop content and campaigns to target the most valuable or receptive segments for a given message or product. This advanced targeting approach focuses marketing creative resources on the right messages for the most valuable customers which yields higher conversion rates and higher revenues from marketing campaigns and reduced waste.'
    ]
const nextBestAction =
    [
        'Next Best Action',
        'The Right Offer at the Right Time with AI',
        'Marketers have come to realize that every customer journey is unique. A touch that is ideal for one customer is ignored by another. The challenge for the marketer is to find the right set of actions for an individual that will move them to the next step in a more complex process or induce them to purchase, if they are ready. ',
        'Selecting the next action to take for each customer is an ideal use case for AI models. AI can be used to find patterns in customer data based on browsing behavior, prior purchases, demographics, household data and more to determine customer interests and intent. One such pattern is the next best action or touchpoint that is likely to produce the desired outcome based on successful outcomes from interactions with other customers combined with the individual’s data. For example, at a certain point in their journey some customers will prefer an email over a phone call. Knowing this and picking the right way to connect is critical to increasing conversions and increasing revenues.'
    ]
const nextBestOffer =
    [
        'Next Best Offer',
        'The Right Offer at the Right Time with AI',
        'Consumers are inundated with marketing messages in their email inbox, online, and by phone. All too often the messages and content they receive are generic and don’t meet their specific needs, which leads to low marketing conversion rates, poor customer satisfaction and customer churn. Traditional offer targeting relies on customer segmentation of marketing lists to target large groups with offers. Even with this segmentation, however, offers are still irrelevant to most recipients. ',
        'AI based machine learning models are ideal for offer optimization. Using AI, marketers can determine which customers are likely to be interested in current offers and only those customers will receive them. With AI models, marketers can use more data on customers including browsing behavior, prior purchases, demographics, household data and more to determine customer interests and intent. AI models can be used to create granular segments of customers using rich data and then to determine the characteristics of products or offers that each group would find most interesting. This can help the marketing team to develop content and offers that would best appeal to each segment which increases conversion rates and dramatically reduces waste in creative content development.'
    ]
const leadScoring =
    [
        'Lead Scoring',
        'Working the Right Leads with AI',
        'Poor lead prioritization can have huge implications for sales and marketing with missed sales goals and demoralized sales teams and ruined collaboration. Poor lead quality drives burnout and high turnover in sales positions leading to extra costs for training and more sales staff on hand to generate the required pipeline. Traditional lead scoring methodologies rely on interest from the prospect to determine a score, but the interest level of the individual does not necessarily provide an indication of their ability to purchase.',
        'AI based machine learning models can score marketing leads using a wider variety of factors and learn from those leads that ultimately became opportunities and those that created revenue. By looking at more information about customer behavior, company size, industry, etc., each lead can be evaluated and scored with sales representatives receiving a ranked list of leads for follow-up. AI can also provide reason codes for each lead so that sales knows the key factors that make the lead valuable. This optimized process insures that sales is working the highest quality leads available which drives faster ramp up from working on consistent leads, quota achievement, less turnover and overall lower sales costs.'
    ]
const customerRetention =
    [
        'Customer Retention',
        'AI Helps Retain Valuable Insurance Customers',
        'It  is widely known in the Insurance industry that retaining a customer is much more cost effective than acquiring a new one. More importantly, high value customers often have a portfolio of products including multiple policies making them even more painful to lose. Simple churn analysis uses rules based on known behaviors to identify potential churn risks. Rules-based systems, however, are inflexible and miss many customers who leave and generate false positives that end up giving expensive incentives to customers who do not need them.',
        'AI is a great solution for customer churn prediction as the problem involves complex data over time and interactions between different customer behaviors that can be difficult for people to identify. AI can look at a variety of data, including new data sources, and at relatively complex interactions between behaviors and compared to individual history to determine risk. AI can also be used to recommend the best offer that will most likely retain a valuable customer. In addition, AI can identify the reasons why a customer is at risk and allow insurance company to act against those areas for the individual customer and more globally.'
    ]
const claimsFraudDetection =
    [
        'Claims Fraud Detection',
        'Using AI to Stop $100 Billion in Fraudulent Healthcare Claims',
        'The U.S. Justice Department estimates that 3% of healthcare claims in the United States, worth almost a hundred billion dollars, are fraudulent. Investigating claims is time consuming and expensive with payers pursuing fraudulent cases for months or years after payments have been made. Fraudulent claims contribute to the increased cost of care, slow down valid claims, and lead to higher healthcare premiums for patients. Traditional manual review doesn’t scale across billions of claims per year and rules-based fraud detection systems are expensive and slow to adapt to new fraud techniques.',
        'AI is ideally suited to fraud detection for medical claims. Machine learning models can be used to automate claims assessment and routing based on existing fraud patterns. This process flags potentially fraudulent claims for further review, but also has the added benefit of automatically identifying good transactions and streamlining their approval and payment. More advanced anomaly detection systems can be deployed to find new patterns and to flag those for review, which leads to prompt investigation of new fraud types. AI systems can also provide clear reason codes for investigators, so they can quickly see the key factors that led the AI to indicate fraud which streamlines their investigation. With AI based fraud detection, fraudulent claims can be evaluated and flagged before they are paid, which reduces costs for payers, helps keep costs lower for patients and helps catch fraudsters in the act.'
    ]
const claimsManagement =
    [
        'Claims Management',
        'Streamlining the Claims Process with AI',
        'For a consumer who has just been in an auto accident or experienced damage to their home, the processes of filing an insurance claim is often a make or break moment for the relationship with their insurance provider. The assessment and payment of the claim should be fast and accurate to ensure customer satisfaction and prevent issues like fraud. Traditional claims management processes are manual with analysts and rules-based systems making choices in claims processing which can slow down the process and make it opaque to consumers and result in a poor customer experience.',
        'AI is ideal for automating repetitive processes and finding anomalous behavior that may indicate fraud or other issues. AI can streamline processing by scoring claims for issues like fraud and allowing claims with low probability of an issue to be processed automatically while higher probability claims are routed to investigators for review. AI models can also provide reason codes for claims denials, which streamlines the review process by allowing the analyst to quickly resolve those issues, so the claim can go through, or by showing the investigator the key issues to focus on. Reason codes are also helpful for customers because they can inform them of issues with their claim which can help them to fix the claim for reprocessing, approval and payment.'
    ]
const personalizedProductBundling =
    [
        'Personalized Product Bundling',
        'Bundling the Right Product with AI',
        'According to a study by J.D. Power &#038; Associates, 46% of customers who own a bundle of insurance services say they “definitely will” renew with their provider vs. 28% of non-bundle policyholders. With a large variety of products to choose from, the pressure to retain customers, and consumer expectations for personalized treatment, marketing and selling generic products is no longer an option. For insurance companies this means finding the right products to cross-sell into customers is critical for success.',
        'AI is well known for helping to recommend products and drive personalization on retail sites and customers have come to expect personalized experiences. According to an Accenture study, more than 80% of insurance customers are looking for more personalized experiences. AI is equally well suited to recommend products and pricing in insurance. With AI models, insurance companies can determine which products and policy options are the best fit for a given consumer. AI can also determine an individualized price based on consumer behavior and historical data. These recommendations can be used in web-based, call center and agent selling scenarios.'
    ]
const personalizedRateManagement =
    [
        'Personalized Rate Management',
        'Customizing Rates based on Individual Data using AI',
        'Determining the rate for an insurance policy has traditionally been driven by simple factors. For example, in auto insurance, the rate was determined by the year, make and model of the vehicle. This method does not consider individualized factors such as driving behavior, location, weather, or time of day, all of which could have a significant impact on individual risk. Assessing individual rates for consumers is difficult using traditional processes because of the volume of data available. Segmenting customers can be helpful, but even these macro groups miss the opportunity presented by the large volumes of data being collected at the individual level.',
        'AI is ideal for anomaly detection, clustering, and creating recommendations, which makes it ideal for finding the issues and developing more individualized insurance policies and rates. For automotive insurance, for example, AI can be used to determine the customer behaviors, such as hard breaking, that lead to more accidents. AI can be used to create granular clusters of customers based on behavior which can be used for segmentation. AI can also be used to evaluate a variety of factors when assessing risk of a serious accident based on the location where most of the driving takes place, city versus highway, daytime or nighttime driving, and other factors. Using more personalized data to make the risk assessment results in an individualized picture of risk and the creation of personalized rates for consumers.'
    ]
const claimDenialsManagement =
    [
        'Claim Denials Management',
        'Streamlining the Claims Process with AI',
        'On average, 24% of claims are denied during the evaluation and payment process, according to the Doctor-Patients Rights Project. Denied claims are large expense for providers and painful for patients who have to pay out-of-pocket or providers who have to write off as losses. Existing claims management processes are highly manual with analysts and rules making choices about which claims to work for resubmission with their limited time. ',
        'An AI approach uses machine learning models to streamline the denials management process by finding claims that have a high likelihood of being paid and the highest potential value. By working these claims first, the providers and payers spend the time on those claims that are most likely to be valid and will yield the most value to patients and providers. AI models can also provide reason codes for the denial which streamlines the review process by allowing the investigator to focus on the key issues. Reason codes are also helpful for patients and providers because they can inform them of issues with their claim and help them to fix the claim for reprocessing or change future claims to avoid issues.'
    ]
const fraudMitigation =
    [
        'Fraud Mitigation',
        'Finding and Stopping Fraud with AI',
        'According to the FBI, the total cost of insurance fraud (non-health insurance) is estimated to be more than $40 billion per year, which costs average U.S. family between $400 and $700 per year in increased premiums. Insurance claims processing is a tedious work which leads to errors. Some fraud types are well known and can be spotted using rules-based systems. However, new or more nuanced fraud is missed by rules-based systems until that fraud becomes well documented.',
        'AI is ideally suited to fraud detection for insurance claims. Machine learning models can be used to automate claims assessment and routing based on existing fraud patterns. This process flags potentially fraudulent claims for further review, but also has the added benefit of automatically identifying good transactions and streamlining their approval and payment. More advanced anomaly detection systems can be deployed to find new patterns and to flag those for review, which leads to prompt investigation of new fraud types. AI systems can also provide clear reason codes for investigators, so they can quickly see the key factors that led the AI to indicate fraud which streamlines their investigation. With AI based fraud detection, fraudulent claims can be evaluated and flagged before they are paid, which reduces costs for insurance providers and helps reduce costs for consumers.'
    ]

const sepsisPrevention =
    [
        'Sepsis Prevention',
        '80% of Sepsis Deaths Can Be Prevented with Rapid Diagnosis',
        'Sepsis is the leading cause of preventable death in U.S. hospitals with mortality from sepsis increasing 8% for every hour that treatment is delayed. As many as 80% of sepsis deaths could be prevented with rapid diagnosis and treatment according to Johns Hopkins Armstrong Institute for Patient Safety and Quality. Diagnosing sepsis can be difficult because its signs and symptoms can be caused by other disorders and there are no reliable biomarkers before onset. Doctors often must order a battery of tests to try to pinpoint the underlying infection which further delays treatment. ',
        'AI is particularly suited to situations where the signals of an issue are hidden by “noise” that obscures the actual problem from clinicians. Diagnosing Sepsis is one of those situations. AI driven early diagnosis based on routine vital signs and metabolic levels from electronic medical records can highlight patients at risk for Sepsis before they are admitted to the ICU where it may already be too late. AI can even be used to predict courses of treatment or dosage levels that are likely to be most effective based on patient history. Though early detection and more precision care, the patient is able to receive less aggressive and less costly treatments which improves patient outcomes and is better for payers and providers. '
    ]
const predictingHospitalReadmissions =
    [
        'Predicting Hospital Readmissions',
        'Preventing Readmissions and Saving Lives with AI',
        'Patients with serious and chronic illnesses are treated in the hospital and then discharged. Unfortunately, according to multiple studies, up to 25% of these patients will be readmitted within 30 days to be treated again, often with less favorable outcomes. With a focus on value-based care, providers are trying to prevent unnecessary readmissions and improve patient care outcomes. Readmission can be significantly reduced by taking steps while the patient is still hospitalized, defining different actions during discharge, and taking steps post discharge to ensure compliance with home care regimens.',
        'AI is ideally suited to tasks where the data inputs are complex and may elude clinicians. Readmissions risk prediction can require data about the specific patient’s recent care, their current condition, treatment, their home life and other risk factors from electronic medical records. AI models can use this information to provide a proactive assessment of their risk and notify clinicians while the patient is still hospitalized. AI can provide the reasons that will lead to readmission and also provide recommendations for the types of treatments that are most likely to be successful given the patient’s history. The reason codes are valuable to clinicians because they can pinpoint areas to focus on when developing a care plan for the patient and prevent unnecessary and costly tests.'
    ]
const improvingClinicalWorkflow =
    [
        'Improving Clinical Workflow',
        'AI Provides a Helping Hand to Clinicians',
        'Clinicians are often overworked, and hospitals are understaffed. Various studies estimate a diagnosis error rate of 10 – 15% which has a huge impact on those patients and the providers. Early diagnosis of critical conditions like Sepsis or Intracranial Hemorrhage has a significant effect on patient outcomes. ',
        'AI based decision support and diagnosis can help clinicians make better decisions by incorporating more data into the decision-making process and by learning patterns that are outside the clinicians’ purview. With mobile devices integrated into the clinical workflow, AI-based decision support helps doctors and nurses by providing a second opinion or by pointing out information they may have missed. These additional insights help the clinician make a more informed decision and can actual save time, expense and patient discomfort by preventing unnecessary tests.'
    ]
const medicalTesting =
    [
        'Medical Testing',
        'Predicting Medical Test Results with AI',
        'According to the National Academy of Medicine, waste in healthcare is widespread and is estimated to be about a fourth of all the money spent on healthcare each year, a staggering $765 billion annually. One of the key areas of waste is unneeded testing or routine tests that are rarely used. The number of tests can add up for an individual patient and result in significant costs that do not contribute to the quality of care or positive patient outcomes.',
        'AI based solutions can be used to help clinicians make better decisions by narrowing the types of tests that are likely to be useful for a patient. AI models can be created using volumes of patient information from healthcare systems together with data from pharmaceutical companies to predict likely test results  a given patients. This model is then deployed into an AI-driven application that can provide indications of which tests are likely to produce definitive or valuable results based on the patient&#8217;s medical history and current symptoms. With this knowledge, the clinician can pursue treatments with the best outcomes and minimize the number of tests, which saves time and reduces costs to the patient. '
    ]
const predictingICUTransfers =
    [
        'Predicting ICU Transfers',
        'Saving Lives by Catching Patients Before the Crash with AI',
        'Studies show that patients who undergo an unplanned transfer to the ICU experience worse outcomes than patients admitted directly. These patients typically stay in the hospital 8 to 12 days longer and have significantly higher mortality rates &#8211; these patients account for only 5% of patients but represent one-fifth of all hospital deaths. The challenge is to find patients before they “crash” and need to be moved to the ICU, but these patients often don’t have symptoms that clinicians can recognize as leading to a serious change in condition.',
        'AI models can be used to find patients who are likely to crash. The machine learning models use patient medical records, laboratory results, and vital signs from patients to find early warning signals of deteriorating condition. These models can then be used with existing patients in realtime to determine their risk of a crash and as part of an early warning system for clinicians so they can intervene before the ICU transfer is needed. The AI system can also provide reason codes for a particular patient, which can help clinicians understand where they should begin their treatment.'
    ]
const predictiveCustomerSupport =
    [
        'Predictive Customer Support',
        'Solving Customer Issues on the First Call',
        'When a consumer or business has support issues they can end up on the phone with multiple support agents for what seems like hours. For many customers this still will not result in a solution and then a service technician will be dispatched to their location. This process is frustrating for customers who are experiencing phone or internet service issues that are impacting their life or business. This process also impacts support employees who experience low job satisfaction and high turnover.',
        'AI can be used to find patterns in support data to find solutions to customer issues. AI models can utilize a variety of data from prior support inquiries and resolutions, network data, weather data and more. AI models can then determine the most likely issues that the customer is facing with the minimal amount of questioning. This more efficient process is better for customers because their issues are resolved more quickly. The process is better for support agents as they work with fewer unhappy customers and they get to help the customer actually solve their issue. Finally, this process has a huge impact on the bottom line for service providers by reducing the number of unnecessary service technician calls which makes these technical resources available for actual network or hardware repairs.'
    ]
const PredictiveFleetMaintenance =
    [
        'Predictive Fleet Maintenance',
        'Preventing Vehicle Downtime with AI',
        'Organizations that depend on a fleet of vehicles for daily operations understand how crucial it can be to keep them running smoothly. Missed appointment for service providers result in customer churn and lost revenues from new installations. Traditional preventative maintenance processes require vehicles to be repaired at regular intervals based on time or usage. These methods, however, still result in instances of vehicle breakdown resulting in accidents, idle workers, lost revenues and angry customers. In addition, preventative maintenance strategies may replace parts that still have significant working life, which can be a waste of time and money.',
        'AI based predictive maintenance uses a variety of data from IoT sensors imbedded in vehicles, fleet data, weather data, and more to determine which components should be replaced before they break down or cause an accident. AI models can look for patterns in data that indicate failure modes for specific components or generate more accurate predictions of the lifespan for a component given environmental conditions and usage. When specific failure signals are observed, or component aging criteria are met, the components can then be replaced during scheduled maintenance windows. AI systems can even warn drivers and fleet managers that components may fail soon, so that they can take proactive measures to change vehicles to keep scheduled appointments. '
    ]
const productRecommendations =
    [
        'Product Recommendations',
        'Increasing Cross-sell and Upsell with AI',
        'McKinsey and Company reports that as much as 35% of Amazon’s onsite revenue is generated by its recommendation engine. Retailers know that recommended products can generate increased cross-sell and up-sell opportunities, but few have actually implemented truly personalized product recommendations on their sites, in emails or other channels. If they contain recommended products at all, many websites and emails still contain static recommendations where everyone who visits that page or receives the email sees the same products. While these recommendations are better than nothing, they are far from the individualized items that consumers now expect.',
        'Every retailer wants to have high quality recommended products everywhere from emails to landing pages, their homepage to categories, product pages and cart to drive increased cross-sell and upsell. AI can be used to find the patterns in customer behavior from clickstream data, prior purchases, demographics and preferences that lead to the best product recommendations for each individual consumer. For example, when a customer visits the homepage of a website, the products can even categories displayed can be based on their known preferences and prior purchases such that the items displayed are highly relevant to them. For emails, product recommendations can be generated as an email is being opened so that the recommended products are relevant based on the customer’s most recent browsing behavior and purchases. With AI, highly relevant and personalized product recommendations, websites, email campaigns, call center agents, and mobile applications can provide personalized experiences for consumers that drive increased conversion rates, basket size and customer loyalty.'
    ]
const assortmentOptimization =
    [
        'Assortment Optimization',
        'Stocking the Right Products to Meet Demand with AI',
        'Traditionally retailers have stocked their stores with the same products based on the season or featuring the current product lineup with only limited variations. Different store locations, however, have different customers, different weather, different display capacity and inventory capacity resulting in very different needs. The result of one-size approach is “stock outs” on hot items and markdowns on others, both costing the retailer hard-won profits. Customer satisfaction and loyalty are also greatly impacted when shoppers cannot find items in the store that they came to see and buy.',
        'AI is ideal for optimizing assortment for retailers. AI models can look at a variety of factors including past sales, store display space, local trends, online behavior, predicted weather patterns, and more to determine which products would be the best fit for a given store location. This AI based optimization prevents stockouts by sending more inventory to stores where products are most needed and minimizes markdowns by making sure that products are on display where they can be sold at full price. AI models can even reroute inventory between stores to ensure that retailers can take advantage of local trends.'
    ]
const pricingOptimization =
    [
        'Pricing Optimization',
        'Maximizing Profits with AI',
        'With retail assortments growing, increasing turnover and with many cases with decreasing store footprints, retailers need new ways to generate profits. Pricing for retailers has typically been driven from the corporate level by established pricing guidelines and competition. Markdowns for many retailers are driven by tried and true techniques with x% at 6 weeks, y% at 8 weeks etc. These traditional methods are insufficient to compete with new online or omni-channel competitors who are better positioned to capture profits through careful price management.  ',
        'AI is ideal for situations where a retailer needs to optimize across a wide assortment of items based on a variety of factors. AI models can be used to determine the best price for each item using data on seasonality and price elasticity along with real-time inputs on inventory levels and competitive products and prices. The result is more careful markdowns on specific colors or versions to a very specific price to increase demand and maximize profits. Marginal price increases are also possible on some items to capture demand from trends. AI can also be used to provide reasons for pricing suggestions that indicate the key factors when making the pricing suggestion. This is helpful to retailers who want to know why particular items are being suggested for markdowns.'
    ]
const predictiveManufacturingDesign =
    [
        'Predictive Manufacturing Design',
        'Finding Optimal Manufacturing Solutions with AI\r\n',
        'Traditional manufacturing processes can require significant investment in prototyping and destructive testing to find safe and cost-effective assembly solutions. This development process is expensive in terms of wasted materials and time consuming for designers and engineers, but necessary to prove that a given design will perform and meet client specifications.',
        'AI can be used to find patterns in manufacturing data that can lead to the best possible manufacturing solutions. By looking at a wide variety of data including materials properties, prior configurations, test results and more, AI based models can determine which combinations of variables are most likely to produce a positive result. Using this information, designers and engineers can pursue avenues that are most likely to work and may even find new solutions they had not thought of before. By focusing on high-probability solutions, manufacturers can reduce costs, speed time to market and improve quality.'
    ]
const predictiveMaintenance =
    [
        'Predictive Maintenance',
        'Fixing Machinery Before a Breakdown with AI\r\n',
        'According the International Society of Automation, a typical factory loses between 5% and 20% of its manufacturing capacity due to downtime. Traditional preventive maintenance processes require machines to be repaired at intervals based on time or usage. These methods, however, still result in significant instances of equipment failure resulting in idle workers, increased scrap rates, lost revenues and angry customers. In addition, preventive maintenance may replace parts that still have significant working life, which can be a waste of time and money.',
        'AI based predictive maintenance uses a variety of data from IoT sensors imbedded in equipment, data from manufacturing operations, environmental data, and more to determine which components should be replaced before they break down. AI models can look for patterns in data that indicate failure modes for specific components or generate more accurate predictions of the lifespan for a component given environmental conditions. When specific failure signals are observed, or component aging criteria are met, the components can then be replaced during scheduled maintenance windows. McKinsey and Company found that AI based predictive maintenance typically generates a 10% reduction in annual maintenance costs, up to a 25% downtime reduction and a 25% reduction in inspections costs.'
    ]
const supplyChainOptimization =
    [
        'Supply Chain Optimization',
        'Finding the best mix across the supply chain',
        'The traditional approach to supply chain management attempts to forecast future demand for resources based on historical data. Supply chain managers then add a safety stock to these levels to prevent stockouts and delays in production. These safety levels can be anywhere from weeks of extra supply to twice normal demand depending on the variation in needs for the product. This inventory level supports the overall production plan including stock levels in individual locations and transportation plans to meet manufacturing needs. Moving and holding extra inventory is a significant expense for manufacturers who are constantly looking for ways to improve profitably.',
        'AI based supply chain optimization can utilize a variety of factors including historical data, environmental data and recent trends to predict optimal resource needs at each stage of production.\n' +
        'AI models can also be used to find anomalous behavior in current resource utilization and pinpoint areas for further investigation by supply chain managers. In retail situations, AI models can determine desirable inventory levels by making tradeoffs between inventory level versus expected sales. AI models can also be used to update resource plans, reroute inventory where it is needed, and streamline resource requirements to reduce downtime, reduce costs, increase production speed and increase profits from manufacturing operations.'
    ]
const transportationOptimization =
    [
        'Transportation Optimization',
        'Ensuring optimal quality of products during transportation and delivery\r\n',
        'Depending upon the types of goods they produce, manufacturers have to ensure that they arrive in good condition. According to the Food and Agricultural Organizations of the United Nations, approximately 40% of food is lost on average in post-harvest and processing stages, in developing countries. This obviously impacts the food manufacturing, processing and transportation companies. Moreover, most industrial manufacturers are sensitive to increases in any transportation cost.',
        'Quality management of products through the transit is crucial. Manufacturers can predict the quality of their products under given transit conditions, hence giving them the opportunity to improve refrigeration (for perishable products) or optimize routes (for raw materials and finished goods). AI based transportation optimization leverages route information, weather data, fuel cost and other such factors to arrive at the best possible route as well as to predict the quality of goods at the destination.'
    ]

const Usecases = (props) => {
    const [type, setType] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        switch (props.match.params.case) {
            case 'customer-churn-prediction':
                return setType(customerChurnPrediction)
            case 'anti-money-laundering':
                return setType(antiMoneyLaundering)
            case 'credit-risk-scoring':
                return setType(creditRiskScoring)
            case 'know-your-customer':
                return setType(kyc)
            case 'fraud-detection':
                return setType(fraudDetection)
            case 'anomaly-detection':
                return setType(anomalyDetection)
            case 'smart-segmentation':
                return setType(smartSegmentation)
            case 'next-best-action':
                return setType(nextBestAction)
            case 'next-best-offer':
                return setType(nextBestOffer)
            case 'lead-scoring':
                return setType(leadScoring)

            case 'customer-retention':
                return setType(customerRetention)
            case 'claims-fraud-detection':
                return setType(claimsFraudDetection)
            case 'claims-management':
                return setType(claimsManagement)
            case 'personlized-product-bundling':
                return setType(personalizedProductBundling)
            case 'personalized-rate-management':
                return setType(personalizedRateManagement)
            case 'claims-denials-management':
                return setType(claimDenialsManagement)
            case 'fraud-mitigation':
                return setType(fraudMitigation)
            case 'sepsis-prevention':
                return setType(sepsisPrevention)
            case 'predicting-hospital-readmissions':
                return setType(predictingHospitalReadmissions)
            case 'improving-clinical-workflow':
                return setType(improvingClinicalWorkflow)
            case 'medical-testing':
                return setType(medicalTesting)
            case 'predicting-icu-transfers':
                return setType(predictingICUTransfers)
            case 'predictive-customer-support':
                return setType(predictiveCustomerSupport)
            case 'predictive-fleet-maintenance':
                return setType(PredictiveFleetMaintenance)
            case 'product-recommendations':
                return setType(productRecommendations)
            case 'assortment-optimization':
                return setType(assortmentOptimization)
            case 'pricing-optimization':
                return setType(pricingOptimization)
            case 'predictive-manufacturing-design':
                return setType(predictiveManufacturingDesign)
            case 'predictive-maintenance':
                return setType(predictiveMaintenance)
            case 'supply-chain-optimization':
                return setType(supplyChainOptimization)
            case 'transportation-optimization':
                return setType(transportationOptimization)
            default:
                return <Redirect to="/home" />




        }
    }, [props])
    return (
        <div>
            <div style={{ position: 'absolute', top: '5%', left: '5%' }}>
                <Link to="/">
                    <button className="btn btn-dark">Home</button>
                </Link>
            </div>
            <div className="industriesBanner">
                <div className="w-75 mx-auto">
                    <div className='display-4' style={{ fontWeight: 'bold' }}>
                        {type[0]}
                    </div>
                    <h2 style={{ fontWeight: '400' }}>{type[1]}</h2>
                </div>
            </div>

            <div className="my-5">
                <div className="row w-75 mx-auto my-5">
                    <div className="col-sm-12 col-md-4 h1">Challenges</div>
                    <div className="col-sm-12 col-md-8" style={{ fontWeight: '500' }}>{type[2]}</div>
                </div>
                <div className="row w-75 mx-auto my-5">
                    <div className="col-sm-12 col-md-4 h1">Opportunities</div>
                    <div className="col-sm-12 col-md-8" style={{ fontWeight: '500' }}>{type[3]}</div>
                </div>
                <div className="row w-75 mx-auto my-5">
                    <div className="col-sm-12 col-md-4 h1">Why Chattel</div>
                    <div className="col-sm-12 col-md-8" style={{ fontWeight: '500' }}>
                        The mission at H2O.ai is to democratize AI for all so that more people across industries can use the power of AI to solve business and social challenges. The financial services industry is a key focus for the company with strong partnerships with leading brands like Wells Fargo, Citigroup, Capital One, PayPal, Discover, Dun & Bradstreet and Equifax helping to drive significant product innovation. H2O Driverless AI is an award-winning platform for automatic machine learning that empowers data science teams to scale by dramatically increasing the speed to develop highly accurate predictive models. Driverless AI includes innovative features of particular interest to financial services including machine learning interpretability (MLI), reason codes for individual predictions, and automatic time series modeling.
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Usecases;