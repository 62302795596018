import React from 'react'
import IndustryCards from '../../Components/IndustryCards/IndustryCards'
import './Blockchain.css'
import Areas from './Areas.png'
import Usecase from './Usecase.png'

const Blockchain = () => {
  return (
    <div>
      <div className='industriesBanner'>
        <div className='w-75 mx-auto'>
          <div className='display-4' style={{ fontWeight: 'bold' }}>
            Blockchain
          </div>
          <h2 style={{ fontWeight: '400' }}>
            Envisioning a blockchain-powered enterprise for your industry
          </h2>
        </div>
      </div>

      <div className='w-75 mx-auto my-5'>
        <h5 className='text-center my-3'>
          <span
            className='p-1'
            style={{ borderBottom: '4px solid rgb(255,229,43)' }}
          >
            Overview
          </span>
        </h5>
        <h1 className='text-center my-4'>AI Transformation in Blockchain</h1>
        <div className='w-75 mx-auto text-center'>
          <h4 style={{ fontWeight: '200' }}>
            Chattel helps clients explore every aspect of blockchain and build
            tailored solutions designed to deliver value. Through architecture,
            digital design, and development, we serve our clients in their quest
            for innovative blockchain solutions that are market-ready and
            address real business issues.
          </h4>
        </div>
        <div
          className='my-5 py-5 text-center display-4'
          style={{ fontWeight: 'bold' }}
        >
          Use-Cases and Customers
        </div>
        <div className='text-center'>
          <img src={Usecase} alt='UseCases' width='70%' />
        </div>
      </div>
      <div className='useCasePanel mt-5 bg-light p-5'>
        <h5 className='text-center my-5'>
          <span
            className='p-1 my-5'
            style={{ borderBottom: '4px solid rgb(255,229,43)' }}
          >
            Use Cases
          </span>
        </h5>
        <div
          className='w-75 mx-auto'
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Auto lending'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-3.jpeg#'
              }
              description={
                ' A platform that simplifies the indirect auto finance process using blockchain capabilities.'
              }
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Bancassurance'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-7.jpeg'
              }
              description={'A distributed ledger technology prototype'}
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Business registry and licensing'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-13.jpeg'
              }
              description={
                'A blockchain-powered business registration and licensing process'
              }
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Cross-border payments'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-9.jpeg'
              }
              description={'A working prototype, part of the digital bank'}
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Crypto tags'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-5.jpeg'
              }
              description={
                'secure digital identity plus marketplace potential for physical goods'
              }
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Customer onboarding'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-2.jpeg'
              }
              description={
                'Creating a seamless onboarding experience for individuals and organizations'
              }
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Fraud detection solution'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-10.jpeg'
              }
              description={
                'This blockchain and machine learning-based fraud detection solution'
              }
              height={40}
            />
          </div>
          <div className='my-3' style={{ width: '47.5%', marginRight: '2.5%' }}>
            <IndustryCards
              title={'Fund distribution'}
              url={
                'https://www.h2o.ai/wp-content/uploads/2021/05/artboard-4-copy-11.jpeg'
              }
              description={
                'The process to purchase a fund is significantly simplified for a customer'
              }
              height={40}
            />
          </div>
        </div>
      </div>
      <img src={Areas} alt='Areas' height='100%' />
    </div>
  )
}

export default Blockchain
