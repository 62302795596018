import React from 'react';
import CHATTEL from '../Pages/Main/chattel.png'
import {Link} from 'react-router-dom'
const Navbar = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#282c34' }} >
                <div className="container">
                    <Link to='/'>
                    <img src={CHATTEL} alt="logo" style={{ width: 200, height: 60, marginRight: 5, alignSelf: 'flex-start' }} />
                    </Link>
                    
                    <a className="navbar-brand" href="#"><h6 style={{ color: 'white' }}></h6></a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i className="fas fa-bars"></i></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item"><a className="nav-link link-scroll active" href="#industries">Industries <span className="sr-only">(current)</span></a></li>
                            <li className="nav-item"><a className="nav-link link-scroll" href="#use_cases">Use Cases</a></li>
                            <li className="nav-item"><a className="nav-link link-scroll" href="#customer_stories">Our Clients</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;